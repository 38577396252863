a:hover, a:visited, a:link, a:active{
  text-decoration: none;
  color: rgba(0, 0, 0, 0.875);
  text-align: center;
  justify-content: center;
  align-items: copy;
  cursor: pointer;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

img {
  margin: 5px;
  -webkit-user-drag: none;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/background.jpg");
  background-repeat: no-repeat center center fixed; 
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
}

code, pre{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2, h3, h1, h4, h5, h6 {
  text-shadow: 0.5px 0.5px 0.8px #9493aa;
  color: #1a1b26;
  cursor: inherit;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-evenly;
}

.elipsText{
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

p {
  text-shadow: 0.5px 0.5px 0.8px #9493aa;
  color: #1a1b26;
  font-size: 1.2em;
  margin: 0.3em;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(61, 61, 66, 0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(44, 44, 46, 0.7);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(35, 35, 39, 0.9);
}

#dvdlogo {
  display: block;
  position: absolute;
  z-index: -1;
  -webkit-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  -moz-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  -o-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  animation-iteration-count:infinite;
}
svg {
  display: block;
}
@-webkit-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@-moz-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@-o-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}

@keyframes changeColour {
  0% { fill: #ff6969; }
  14% { fill: #fd9644; }
  28% { fill: #fed330; }
  42% { fill: #2dc22d; }
  56% { fill: #45d8f2; }
  70% { fill: #5e6cea; }
  84% { fill: #c22dc2; }
  100% { fill: #ff6969; }
}

@-webkit-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@-moz-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@-o-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}


#header {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: rgba(214, 214, 214, 0.80);
}
#bodyContent {
  height: calc( 100vh - 50px);
  max-height: calc( 100vh - 50px);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
}
#sideContent {
  background-color: rgba(214, 214, 214, 0.8);
  backdrop-filter: blur(6px);
  box-shadow: 9px 13px 8px 8px  rgba(27, 23, 19, 0.692);
  border-bottom-right-radius: 1em;
  max-width: 500px;
  width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.mainContent {
  overflow: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 999;
}
.rightContent {
  overflow: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.fullGrafana {
  width: 100%;
}
.halfGrafana {
  width: 50%;
}

.lightText {
  color: rgba(176, 175, 218, 0.7);
}

.lightTextAlt {
  color: rgba(220, 220, 235, 0.7);
  text-shadow: 0.5px 0.5px 0.8px #5a5663;
}

.darkText {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.875);
  text-shadow: 0.5px 0.5px 0.8px #5a5663;
}

.darkTextSmoll {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.875);
  text-shadow: 0.5px 0.5px 0.8px #5a5663;
  font-weight: normal;
  font-size: small;
}

.hostInfo {
  cursor: default;
  text-align: start;
  padding: 0;
  margin: 0;
  user-select: text;
  font-size: small;
  color: rgba(15, 15, 15, 0.8750);
  background-color: rgba(206, 206, 206, 0.8);
  -webkit-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.3);
  -moz-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.3);
  box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.3);
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-radius: 1em;
}

.infoBar {
  cursor: default;
  text-align: start;
  padding: 0;
  margin: 0;
  user-select: text;
  font-size: small;
  color: rgba(15, 15, 15, 0.8750);
  background-color: rgba(192, 188, 202, 0.335);
  -webkit-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.2);
  -moz-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.2);
  box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.2);
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-radius: 0.5em;
}

.infoContainer {
  cursor: default;
  text-align: start;
  padding: 0;
  margin: 0;
  user-select: text;
  font-size: small;
  color: rgba(15, 15, 15, 0.8750);
  background: rgba(2, 0, 36, 0.5);
  background: linear-gradient(120deg, rgba(4, 3, 24, 0.10) 0%, rgba(78, 78, 85, 0.05) 35%, rgba(49, 79, 85, 0.10) 100%);
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-radius: 0.5em;
}

.insetEffect {
  background-color: rgba(206, 206, 206, 0.26);
  -webkit-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.05);
  -moz-box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.05);
  box-shadow: inset 3px 3px 12px 2px rgba(62, 62, 104, 0.05);
  width: 100%;
}

.smallTxt {
  font-size: small;
}

.verticalSeparator {
  width: 0.2em;
  border-left: dashed 2px rgba(72, 80, 79, 0.4);
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  align-items: stretch;
}

.verticalDivider {
  height: 20px;
  width: 100%;
  display: flex;
  align-items: stretch;
}

.halfVerticalDivider {
  height: 10px;
  display: flex;
  align-items: stretch;
}

.smallVerticalDivider {
  height: 5px;
  display: flex;
  align-items: stretch;
}

.flexContainer {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.makeItWide {
  width: 50vw;
}

.forceVertical {
  flex-direction: column;
}

.forceWrap{
  flex-direction: column;
}

.stretchAndPad {
  justify-content: stretch;
  padding: 20px;
  width: 100%;
}

.stretchAndBetween {
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.stroke {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.strokeSmoll {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.strokeSmollLeft {
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  vertical-align: middle;
  flex-direction: column;
  z-index: 2;
}

.row {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.sideMargin {
  margin-left: 1em;
  margin-right: 1em;
}

.fullMargin {
  margin: 2em;
}

.withWrap {
  flex-wrap: wrap;
}

.alignEnd {
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.rowAlignLeft {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

.rowAlignRight {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

.waveButton {
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(157, 157, 158, 0.9);
  box-shadow: 4px 5px 3px 2px rgba(20, 20, 19, 0.692);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  z-index: 3;
  backdrop-filter: blur(6px);
}
.waveButton:hover {
  background-color: rgba(192, 195, 200, 0.8);
}
.waveButton:disabled {
  cursor: default;
  opacity: 0.7;
}

.homeButton {
  cursor: pointer;
  padding: 8px;
  border: 0;
  border-radius: 20px;
  background-color: transparent;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  z-index: 10; 
}
.homeButton:hover {
  box-shadow: 4px 5px 3px 2px rgba(20, 20, 19, 0.692);
  backdrop-filter: blur(6px);
}
.homeButton:active {
  box-shadow: inset -4px -5px 6px 4px rgba(20, 20, 19, 0.692);
}
.homeButton:disabled {
  cursor: default;
  opacity: 0.7;
}
.nonHomeButton{
  cursor: pointer;
  padding: 0.1em;
  border: 0;
  border-radius: 20px;
  background-color: transparent;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  z-index: 10; 
  margin-bottom: 0.7em;
}
.nonHomeButton:hover {
  box-shadow: 4px 5px 3px 2px rgba(20, 20, 19, 0.692);
  backdrop-filter: blur(6px);
}
.nonHomeButton:active {
  box-shadow: inset -4px -5px 6px 4px rgba(20, 20, 19, 0.692);
}
.buttonPadding {
  padding: 1em;
}
.active {
  box-shadow: inset -4px -5px 6px 4px rgba(20, 20, 19, 0.692);
}
.searchField {
  margin: auto;
  display: flex;
  background-color: rgba(107, 111, 122, 0.199);
  padding: 5px;
  border-radius: 20px;
  color:rgba(20, 20, 19, 0.747);
}
.searchField::placeholder {
  color:rgba(20, 20, 19, 0.445);
}
.searchField:focus {
  outline: none !important;
  box-shadow: 0px 0px 3px 2px rgba(99, 109, 112, 0.8);
}

.main-container {
  display: flex;
  flex-direction: column;
}

.fixed-container {
  height: 50px;
  padding: 10px;
  border-radius: 50px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  vertical-align: middle;
}

.content-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
}

.overflow-container {
  flex: 1;
  overflow: auto;
}
.overflow-content {
  color: black;
  padding-left: 1em;
  padding-right: 1em;
}

.onlyVerticalScroll {
  overflow-y: auto;
  overflow-x: hidden;
}


.noCursor {
  cursor: default;
}

.roundedOpaque {
  background-color: rgba(214, 214, 214, 0.80);
  box-shadow: 9px 13px 18px 8px  rgba(20, 20, 19, 0.692);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  border-radius: 1em;
}

.roundedOpaqueDark {
  background-color: rgba(5, 5, 10, 0.95);
  box-shadow: 9px 13px 18px 8px  rgba(5, 5, 10, 0.6);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  border-radius: 1em;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.container {
  position: relative;
  text-align: center;
}

.alwaysOnBottom {
  z-index: 0;
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0);
  margin: 0 auto;
  user-select: none;
}

.selectOrch, .selectOrch a{
  text-shadow: 0.5px 0.5px 0.8px #9493aa;
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.selectOrch:hover, .selectOrch a:hover{
  backdrop-filter: blur(6px);
  text-decoration: none;
  cursor: pointer;
  border-radius: 1em;
}

.selectOrchLight, .selectOrchLight a{
  text-shadow: 0.5px 0.5px 0.8px #9493aa;
  background-color: transparent;
  border: none;
}

.selectOrchLight:hover, .selectOrchLight a:hover{
  backdrop-filter: blur(6px);
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(187, 187, 187, 0.9);
  border-radius: 1em;
}

.toggle-container {
  width: 70px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 32px;
  position: relative;
}

.dialog-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #002b49;
  color: white;
  padding: 8px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #495258;
  left: 2px;
}

@media (max-aspect-ratio: 4/3) {
  .mobileSmallerFont{
    font-size: xx-small;
  }
  .fullGrafana {
    width: calc(100vw - 2em);
  }
  .halfGrafana {
    width: calc(100vw - 2em);
  }
  .shrinkSize {
    width: 50%;
  }
  .flexContainer {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
  }
  .roundedOpaque {
    background-color: none;
    width: 100%;
    box-shadow: none;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .stroke {
    margin-left: 0;
    margin-right: 0;
  }
  .showOnlyOnMobile {
    visibility: visible;
    display:block;
  }
  .showNeverOnMobile {
    visibility: hidden;
    display:none;
  }
  .elipsOnMobile {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 10vw;
    text-align: start;
  }
  .elipsOnMobileExtra {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 12vw;
    text-align: start;
  }
  .mobileNoPadding {
    padding: 0;
  }
  .mobileNoMargin {
    margin: 0;
  }
  .mobileNoSideMargin {
    margin-left: 0;
    margin-right: 0;
  }
  .main-container {
    height: calc(100vh - 60px);
  }
}
